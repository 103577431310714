import React, { useState } from "react";
import {
  Block,
  Button,
  List,
  ListItem,
  Icon,
  f7,
  Popup,
  Preloader,
} from "framework7-react";
import { Copy, X } from "lucide-react";
import WebApp from "@twa-dev/sdk";
import { CopyToClipboard } from "react-copy-to-clipboard";

const LockedScreen = ({ services, onStarsPayment }) => {
  const [showIframe, setShowIframe] = useState(false);
  const [starsLoading, setStarsLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const handleStarsPayment = async () => {
    const token = localStorage.getItem("authToken");
    setStarsLoading(true);
    try {
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/stars-payment?bundle_uuid=27982372-5706-499e-b7b5-e95e675fc930",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        // console.log(data);
        setInvoice(data.data);
        WebApp.openInvoice(data.data);
      } else {
        f7.dialog.alert("Failed to check user status. Please try again.");
      }
    } catch (error) {
      console.error("Error checking user status:", error);
      f7.dialog.alert("Error checking user status. Please try again.");
    } finally {
      setStarsLoading(false);
    }
  };
  const handleUPIPayment = () => {
    setShowIframe(true);
  };
  return (
    <div className="column-alignment locked-screen-container">
      {/* Lock Icon and Heading */}
      <Block className="locked-screen-header">
        <i className="f7-icons locked-icon">lock_fill</i>
        <h2 className="locked-title">This Feature is Locked</h2>
        <p className="locked-subtitle">Unlock with Stars</p>
      </Block>

      {/* Display services */}
      <Block className="locked-screen-services">
        <List noHairlinesMd>
          {services.map((service, index) => (
            <ListItem
              key={index}
              title={service.name}
              after={`${service.cost} Stars`}
              className="service-item"
            />
          ))}
        </List>
      </Block>

      {/* Payment Button */}
      <div
        className="column-alignment locked-screen-footer"
        style={{ gap: "12px" }}
      >
        <Button
          fill
          large
          onClick={handleStarsPayment}
          className="unlock-button"
          style={{
            backgroundColor: "#1a1f26",
            color: "#a8b3cf",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          Unlock using stars
          {starsLoading && <Preloader />}
        </Button>
        <Button
          fill
          large
          onClick={handleUPIPayment}
          className="unlock-button"
          style={{
            backgroundColor: "#1a1f26",
            color: "#a8b3cf",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          Pay using UPI
        </Button>
      </div>

      {/* Iframe Popup for UPI Payment */}
      <Popup
        opened={showIframe}
        onPopupClose={() => setShowIframe(false)}
        className="iframe-popup"
      >
        <div
          style={{ margin: "10px", padding: 0 }}
          className="column-alignment"
        >
          {/* New header with ID and close button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Telegram Id {WebApp?.initDataUnsafe?.user?.id}</span>
              <CopyToClipboard
                text={WebApp?.initDataUnsafe?.user?.id ?? ""}
                onCopy={() => f7.dialog.alert("ID copied to clipboard")}
              >
                <Copy size={20} style={{ marginLeft: 5 }} />
              </CopyToClipboard>
            </div>
            <X onClick={() => setShowIframe(false)} size={20} />
          </div>

          <iframe
            src="https://rzp.io/l/ffFdZjg"
            style={{
              width: "100%",
              border: "none",
              height: "calc(100vh - 54px)",
            }}
            title="UPI Payment"
          ></iframe>
        </div>
      </Popup>
    </div>
  );
};

export default LockedScreen;
