import { Popover, Block } from "framework7-react";
import "./verified-badge-popover.css";
import renderVerifiedBadge from "../../components/author-badge/author-badge";

const VerifiedBadgePopover = ({ author }) => {
  return (
    <Popover className="popover-verified-badge" target=".verified-badge">
      <Block style={{ minWidth: "150px", margin: 0, padding: "12px" }}>
        <div className="column-alignment" style={{ alignItems: "center" }}>
          {renderVerifiedBadge(author?.level, "25")}
        </div>
        <p style={{ margin: "8px 0", textAlign: "center" }}>
          Mentor with high placement/success rates at Preplaced.
        </p>
      </Block>
    </Popover>
  );
};

export default VerifiedBadgePopover;
