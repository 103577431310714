import {
  Page,
  Block,
  Toolbar,
  Link,
  Tabs,
  Tab,
  Navbar,
  NavRight,
  NavLeft,
  f7,
} from "framework7-react";
import NavBar from "../../components/navbar";
import { useState, useEffect } from "react";
import SkeletonPosts from "../../components/skeleton/skeleton-posts/skeleton-posts";
import PostCard from "../../components/post-card/post-card";
import SkeletonJobs from "../../components/skeleton/skeleton-jobs/skeleton-jobs";
import SkeletonTopVoice from "../../components/skeleton/skeleton-top-voice/skeleton-top-voice";
import AuthorProfile from "../../components/author-card/author-card";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";

const UserProfilePage = () => {
  const [username, setUsername] = useState("");
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);
  const [bookmarkedJobs, setBookmarkedJobs] = useState([]); // Only 'job' category
  const [bookmarkedAuthors, setBookmarkedAuthors] = useState([]); // Filter by available authors
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingRemoveBookmark, setLoadingRemoveBookmark] = useState(false);

  // Retrieve username from localStorage when the component mounts
  useEffect(() => {
    const storedProfile = localStorage.getItem("profile");
    if (storedProfile) {
      // Parse the JSON string into an object
      const profileObject = JSON.parse(storedProfile);
      console.log(profileObject.username); // Check if username exists in the parsed object
      if (profileObject.username) {
        setUsername(profileObject.username);
      }
    } else {
      console.log("Profile not found in localStorage");
    }
  }, []);

  const getBookmarks = async () => {
    const token = localStorage.getItem("authToken");
    try {
      setLoadingPosts(true);
      const response = await fetch(
        `https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-bookmarks`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const bookmarkResponse = await response.json();
        const bookmarkedPosts = bookmarkResponse.data.filter(
          (bookmark) => bookmark.posts?.category === "post"
        );
        setBookmarkedPosts(bookmarkedPosts);

        const bookmarkedJobs = bookmarkResponse.data.filter(
          (bookmark) => bookmark.posts?.category === "job"
        );
        setBookmarkedJobs(bookmarkedJobs);

        const bookmarkedAuthor = bookmarkResponse.data.filter(
          (bookmark) => bookmark.post_id === null
        );
        setBookmarkedAuthors(bookmarkedAuthor);
      } else {
        console.error(`Failed to fetch bookmarks posts`);
      }
    } catch (error) {
      console.error(`Error fetching bookmarks posts`, error);
    } finally {
      setLoadingPosts(false);
    }
  };

  useEffect(() => {
    getBookmarks();
  }, []);

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      setLoadingRemoveBookmark(true);
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/bookmark",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: bookmarkId,
          }),
        }
      );

      if (response.ok) {
        const bookmarkResponse = await response.json();
        console.log(bookmarkResponse, "removebookmark");
        f7.toast
          .create({
            // text: isBookmarked
            // ? "Bookmark removed successfully!"
            // :
            text: "Bookmark removed successfully!",
            closeTimeout: 2000,
            position: "top",
          })
          .open();
        getBookmarks();
      } else {
        f7.toast
          .create({
            text: "Failed to remove bookmark.",
            closeTimeout: 2000,
            position: "top",
          })
          .open();
      }
    } catch (error) {
      f7.toast
        .create({
          text: "Error removing bookmark.",
          closeTimeout: 2000,
          position: "top",
        })
        .open();
      console.error("Remove bookmark error:", error);
    } finally {
      setLoadingRemoveBookmark(false); // Stop loader
    }
  };

  return (
    <Page pageContent={false}>
      <NavBar
        showBackButton={true}
        showUserName={true}
        username={username}
        showJoinCommunity={false}
      />
      <Toolbar top tabbar>
        <Link
          style={{ fontSize: 14, textAlign: "center" }}
          tabLink="#bookmarked-posts"
          tabLinkActive
        >
          Bookmarked <br />
          Posts
        </Link>
        <Link
          style={{ fontSize: 14, textAlign: "center" }}
          tabLink="#bookmarked-jobs"
        >
          Bookmarked <br />
          Jobs
        </Link>
        <Link
          style={{ fontSize: 14, textAlign: "center" }}
          tabLink="#bookmarked-authors"
        >
          Bookmarked <br />
          Authors
        </Link>
      </Toolbar>

      <Tabs swipeable>
        <Tab id="bookmarked-posts" className="page-content" tabActive>
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonPosts />
                ) : (
                  bookmarkedPosts.map((post, index) => (
                    <PostCard
                      key={index}
                      bookmarkId={post.id}
                      post={post.posts}
                      showBookMark={false}
                      showRemoveBookmark={true}
                      handleRemoveBookmark={handleRemoveBookmark}
                      loadingRemoveBookmark={loadingRemoveBookmark}
                    />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="bookmarked-jobs" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonJobs />
                ) : (
                  bookmarkedJobs.map((post, index) => (
                    <PostCard
                      key={index}
                      bookmarkId={post.id}
                      post={post.posts}
                      showBookMark={false}
                      showRemoveBookmark={true}
                      handleRemoveBookmark={handleRemoveBookmark}
                    />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="bookmarked-authors" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonTopVoice />
                ) : (
                  bookmarkedAuthors.map((author, index) => (
                    <AuthorProfile
                      key={index}
                      bookmarkId={author.id}
                      author={author.authors}
                      showBookMark={false}
                      showRemoveBookmark={true}
                      handleRemoveBookmark={handleRemoveBookmark}
                      loadingRemoveBookmark={loadingRemoveBookmark}
                    />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
      </Tabs>
    </Page>
  );
};

export default UserProfilePage;
