import {
  Calendar,
  Trophy,
  Clock,
  Globe,
  Users,
  CalendarClock,
} from "lucide-react";
import "./upcoming-events-card.css";
import { Card, CardHeader, CardContent, f7 } from "framework7-react";

const UpcomingEventsCard = ({ event }) => {
  const handleEventClick = () => {
    console.log("event clicked");
    f7.views.main.router.navigate("/upcoming-event", {
      props: {
        event: event,
      },
    });
  };
  return (
    <div className="column-alignment" onClick={handleEventClick}>
      <Card className="event-card">
        <CardHeader className="event-card-header">
          <div className="event-tag row-alignment">
            <CalendarClock size={16} className="align-self-center" />{" "}
            &nbsp;Event
          </div>
          <img src={event.bannerUrl} alt="Event" className="event-image" />
          <div className="event-date">{event.date}</div>
        </CardHeader>
        <CardContent className="column-alignment">
          <h2 className="event-title">{event.title}</h2>
          <div className="event-detail">
            <i className="f7-icons" style={{ fontSize: "16px" }}>
              clock
            </i>
            <span>{event.time}</span>
          </div>
          <div className="event-detail">
            <i className="f7-icons" style={{ fontSize: "16px" }}>
              globe
            </i>
            <span>{event.location}</span>
          </div>
          <div className="event-detail">
            <i className="f7-icons" style={{ fontSize: "16px" }}>
              person_2
            </i>
            <span>{event.attendeesCount} attending</span>
          </div>
          <div className="event-price align-self-start">Free</div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UpcomingEventsCard;
