import "./achievement-card.css";

const AchievementCard = ({ achievement }) => {
  return (
    <div className="achievement-card column-alignment align-center">
      <img src={achievement.image} alt={achievement.achievement} className="achievement-image" />
      <span className="achievement-title">{achievement.achievement}</span>
      <span className="achievement-description">{achievement.description}</span>
    </div>
  );
};
export default AchievementCard;
