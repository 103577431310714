import HomeScreen from "./pages/home-screen/home-screen";
import ReadPostScreen from "./pages/read-post-screen/read-post-screen";
import AuthorScreen from "./pages/author-screen/author-screen";
import QuizGame from "./pages/game-screen";
import IframeScreen from "./pages/iframe-page/iframe-page";
import ResumeReviewPage from "./pages/resume-review-page/resume-review-page";
import UserProfilePage from "./pages/user-profile-page/user-profile-page";
import UpcomingEventPage from "./pages/upcoming-event-page/upcoming-event-page";
const routes = [
  {
    path: "/",
    component: HomeScreen,
  },
  {
    path: "/read-post",
    component: ReadPostScreen,
  },
  {
    path: "/author-screen",
    component: AuthorScreen,
  },
  {
    path: "/iframe",
    component: IframeScreen,
  },
  {
    path: "/resume-review",
    component: ResumeReviewPage,
  },
  {
    path: "/user-profile",
    component: UserProfilePage,
  },
  {
    path: "/upcoming-event",
    component: UpcomingEventPage,
  },
];
export default routes;
