import {
  Block,
  Page,
  Navbar,
  NavRight,
  Tabs,
  Tab,
  Link,
  Toolbar,
  Icon,
  f7,
  Card,
  CardContent,
  Row,
  Col,
  Button,
} from "framework7-react";
import TopmateCard from "../../components/bio-cards/topmate-card";
import { useEffect, useState } from "react";
import PostCard from "../../components/post-card/post-card";
import { FaLinkedin, FaTwitter, FaTelegram } from "react-icons/fa";
import WebApp from "@twa-dev/sdk";
import { postHogHandler } from "../../Posthog/posthog";
import "./author-screen.css";
import renderVerifiedBadge from "../../components/author-badge/author-badge";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import profileShareIcon from "../../assets/images/sharing.png";

import quickTakes from "./quick-takes.json";
import QuickTakeCard from "../../components/quick-take-card/quick-take-card";
import VerifiedBadgePopover from "../../components/verified-badge-popover/verified-badge-popover";
import careerJourney from "../../utils/career-journey.json";
import CareerJourneyCard from "../../components/career-journey-card/career-journey-card";
import UpcomingEventsCard from "../../components/upcoming-events-card/upcoming-events-card";
import upcomingEvents from "../../utils/upcoming-events.json";
import ProfileShareBottomSheet from "./profile-share-bottom-sheet";
import AchievementsBottomSheet from "./achievements-bottom-sheet";
import experiences from "../../utils/career-journey.json";
import { Share } from "lucide-react";

const AuthorScreen = ({ uuid }) => {
  const authorProfileLink = `https://t.me/TigestBot/TigestBot?startapp=${uuid}`;
  const careerJourneyArray = careerJourney.data;
  const [popoverOpened, setPopoverOpened] = useState(false);
  const quickTakesjsonArray = quickTakes.data;
  const [author, setAuthor] = useState(null);
  const experiencesArray = experiences;

  const upcomingEventsArray = upcomingEvents.data;
  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const response = await fetch(
          `https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-author?uuid=${uuid}`,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAuthor(data);
        } else {
          console.error("Failed to fetch author data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAuthor();
  }, [uuid]);

  const SocialLink = ({ href, icon, telegram }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="socialLink"
      onClick={() => {
        const link = href.includes("linkedin")
          ? "linkedin"
          : href.includes("x")
          ? "twitter"
          : "telegram";
        postHogHandler(`${link}-link-click`, {
          name: author?.name,
          uuid: author?.uuid,
        });
        telegram ? WebApp.openTelegramLink(href) : WebApp.openLink(href);
      }}
    >
      {icon}
    </a>
  );

  return (
    <Page>
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      {author && (
        <Block style={{ paddingRight: 0, paddingLeft: 0, paddingTop: 10 }}>
          <div className="column-alignment">
            <div className="authorProfileSection">
              <div className="row-alignment">
                <img
                  src={author?.image}
                  alt={author?.name}
                  className="authorImage"
                />
                <span className="fill"></span>
                <Button
                  popupOpen=".achievements-bottom-sheet"
                  style={{ textTransform: "none" }}
                >
                  <div className="row-alignment" style={{ gap: "4px" }}>
                    <i
                      className="fa-solid fa-star"
                      style={{ fontSize: "14px" }}
                    ></i>
                    <i
                      className="fa-solid fa-star"
                      style={{ fontSize: "14px" }}
                    ></i>
                    <i
                      className="fa-solid fa-ellipsis"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </div>
                </Button>
              </div>

              <div
                className="row-alignment"
                style={{ marginTop: "12px", gap: "4px" }}
              >
                <h1 className="authorName" style={{ fontSize: "22px" }}>
                  {author?.name}{" "}
                  {author?.is_verified && (
                    <>
                      <Link
                        popoverOpen=".popover-verified-badge"
                        className="verified-badge"
                      >
                        {renderVerifiedBadge(author?.level, "18")}
                      </Link>
                    </>
                  )}
                </h1>
                <Button
                  popupOpen=".profile-share-bottom-sheet"
                  style={{ textTransform: "none", padding: 0 }}
                  className="align-self-center"
                >
                  <Share size={18} />
                </Button>
              </div>

              {/* Popover for verified badge information */}
              <VerifiedBadgePopover author={author} />

              <div className="authorBio">{author?.bio}</div>

              <div className="socialLinks">
                {author?.linkedin && (
                  <SocialLink
                    href={author?.linkedin}
                    icon={<FaLinkedin size={25} color="white" />}
                  />
                )}
                {author?.twitter && (
                  <SocialLink
                    href={author?.twitter}
                    icon={<FaTwitter size={25} color="white" />}
                  />
                )}
                {author?.telegram && (
                  <SocialLink
                    telegram={true}
                    href={author?.telegram}
                    icon={<FaTelegram size={25} color="white" />}
                  />
                )}
              </div>
            </div>

            <div className="divider" />
            <Toolbar tabbar scrollable>
              <Link
                style={{ fontSize: 14, maxWidth: 500 }}
                tabLink="#about"
                tabLinkActive
              >
                About
              </Link>
              <Link style={{ fontSize: 14, maxWidth: 500 }} tabLink="#products">
                Products
              </Link>
              <Link style={{ fontSize: 14, maxWidth: 500 }} tabLink="#events">
                Events
              </Link>

              <Link
                style={{ fontSize: 14, maxWidth: 500 }}
                tabLink="#quick-takes"
              >
                Quick Takes
              </Link>
              <Link
                style={{ fontSize: 14, maxWidth: 500 }}
                tabLink="#author-posts"
              >
                Posts
              </Link>
            </Toolbar>

            <Tabs
              swipeable
              style={{ display: "flex", overflowY: "scroll", height: "700px" }}
            >
              <Tab
                tabActive
                id="about"
                style={{
                  paddingTop: 0,
                }}
              >
                <Block
                  style={{
                    marginTop: "16px",
                    paddingTop: 0,
                  }}
                >
                  <span className="heading-text">Career Journey</span>
                  <CareerJourneyCard experiences={experiencesArray} />
                </Block>
              </Tab>
              <Tab
                id="products"
                className="page-content"
                style={{ paddingTop: 0 }}
              >
                <Block
                  className="topmateLinks"
                  style={{ marginTop: "16px", paddingTop: 0 }}
                >
                  <span className="heading-text">Top Services</span>
                  {author?.topmate_links
                    ? Object.keys(author?.topmate_links).map((key, index) => (
                        <TopmateCard
                          title={author?.topmate_links[key]["title"]}
                          subtitle={author?.topmate_links[key]["sub-title"]}
                          iframeSrc={author?.topmate_links[key]["link"]}
                          key={index}
                        />
                      ))
                    : null}
                </Block>
              </Tab>
              <Tab
                id="events"
                className="page-content"
                style={{ paddingTop: 0 }}
              >
                <Block style={{ marginTop: "16px", paddingTop: 0 }}>
                  <span className="heading-text">Upcoming Events</span>
                  <div className="column-alignment">
                    <div className="upcomingEventsList">
                      {upcomingEventsArray.map((event, index) => (
                        <UpcomingEventsCard key={index} event={event} />
                      ))}
                    </div>
                  </div>
                </Block>
              </Tab>

              <Tab
                id="quick-takes"
                className="page-content"
                style={{ paddingTop: 0 }}
              >
                <Block style={{ marginTop: 0 }}>
                  {quickTakesjsonArray.map((quickTake, index) => (
                    <QuickTakeCard key={index} quickTake={quickTake} />
                  ))}
                </Block>
              </Tab>
              <Tab
                id="author-posts"
                className="page-content"
                style={{ paddingTop: 0 }}
              >
                <Block style={{ marginTop: 0, padding: 0 }}>
                  {author?.posts && author.posts.length > 0 ? (
                    author.posts
                      .filter((post) => post.category === "post")
                      .sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((post, index) => (
                        <PostCard key={index} post={post} author={false} />
                      ))
                  ) : (
                    <p style={{ padding: "16px" }}>No posts available.</p>
                  )}
                </Block>
              </Tab>
            </Tabs>
          </div>
        </Block>
      )}
      <ProfileShareBottomSheet profileLink={authorProfileLink} />
      <AchievementsBottomSheet />
    </Page>
  );
};

export default AuthorScreen;
