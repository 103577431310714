import { Block, PageContent, Sheet, f7 } from "framework7-react";
import AchievementCard from "../../components/achievement-card/achievement-card";
import achievements from "../../utils/achievements.json";

const AchievementsBottomSheet = () => {
  const achievementsArray = achievements.data;

  const handleClose = () => {
    f7.sheet.close(".achievements-bottom-sheet");
  };

  return (
    <Sheet
      className="achievements-bottom-sheet"
      style={{ height: "100%" }}
      push
      backdrop
    >
      <PageContent
        className=""
        style={{
          background: "#282829",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Block
          className="column-alignment"
          style={{ padding: "16px", marginTop: 0, width: "100%" }}
        >
          <div
            className="row-alignment"
            style={{
              padding: "16px",
              position: "fixed",
              top: 0,
              right: 0,
              left: 0,
              zIndex: 1000,
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.5)",
              background: "#282829",
            }}
          >
            <span className="fill"></span>
            <i
              className="f7-icons"
              onClick={handleClose}
              style={{ pointer: "cursor" }}
            >
              multiply
            </i>
          </div>
          <div className="column-alignment" style={{ marginTop: "56px" }}>
            <div className="column-alignment" style={{ gap: "10px" }}>
              {achievementsArray.map((achievement, index) => (
                <AchievementCard key={index} achievement={achievement} />
              ))}
            </div>
          </div>
        </Block>
      </PageContent>
    </Sheet>
  );
};

export default AchievementsBottomSheet;
