import { Block, Page, f7, Button, Preloader } from "framework7-react";
import { useState, useRef } from "react";
import NavBar from "../../components/navbar";
import { formatDate } from "../../utils/format-date";
import { postHogHandler } from "../../Posthog/posthog";
import { useEffect } from "react";
import renderVerifiedBadge from "../../components/author-badge/author-badge";
import commentsListJson from "../../utils/comments-list.json";
import CommentWrapper from "../../components/comment-wrapper/comment-wrapper";
import "./read-post-screen.css";
import AddCommentSheet from "./add-comment-sheet";
const ReadPostScreen = (props) => {
  const [isAddCommentSheetOpen, setIsCommentSheetOpen] = useState(false);
  // const comments = commentsListJson.data;
  const [comments, setComments] = useState([]);
  const [noOfComments, setNoOfComments] = useState();
  const [loadingComments, setLoadingComments] = useState(false);
  const { post } = props;

  const fetchCommentsData = async () => {
    setLoadingComments(true);
    if (post.category === "resume") {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-resume-comments",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              resume_id: post.id, // Add your resume_id
            }),
          }
        );
        const data = await response.json();
        console.log("comments data", data);
        setComments(data.data);
        setNoOfComments(data.data.length); // Save fetched data to state
      } catch (error) {
        console.error("Error fetching resume:", error);

      } finally {
        setLoadingComments(false);
      }
    }
  };

  useEffect(() => {
    fetchCommentsData();
  }, [post.category]); // Dependency on post.category

  useEffect(() => {
    postHogHandler("read-post-page", {
      ...post,
    });
  }, []);

  const handleAuthorClick = () => {
    f7.views.main.router.navigate("/author-screen", {
      props: {
        uuid: post?.authors?.uuid,
      },
    });
    postHogHandler("home-page-author-clicked", {
      uuid: post?.authors?.uuid,
      name: post?.authors?.name,
      link: post?.link,
    });
  };

  const sheet = useRef(null);
  const onPageBeforeOut = () => {
    f7.sheet.close();
  };
  const onPageBeforeRemove = () => {
    if (sheet.current) sheet.current.destroy();
  };

  return (
    <>
      <Page
        onPageBeforeOut={onPageBeforeOut}
        onPageBeforeRemove={onPageBeforeRemove}
      >
        <NavBar
          showBackButton={true}
          showReadPost={true}
          postRedirectLink={post.link}
        />
        <Block style={{ margin: 0, paddingBottom: "18px", paddingTop: "44px" }}>
          <div className="column-alignment">
            <span
              style={{
                fontWeight: "700",
                fontSize: "20px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                marginTop: 20,
              }}
              onClick={handleAuthorClick}
            >
              {post.category !== "job" && post.authors?.image && (
                <>
                  <img
                    src={post.authors.image}
                    style={{
                      objectFit: "cover",
                      borderRadius: "12px",
                      height: "40px",
                      marginRight: "10px",
                    }}
                  />
                  <div style={{ fontSize: "18px" }}>{post.authors.name}</div>
                  {post.authors.is_verified &&
                    renderVerifiedBadge(post.authors.level)}
                </>
              )}
            </span>
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38px",
                margin: "24px 0 12px 0",
              }}
            >
              {post.title}
              <div
                className="row-alignment"
                style={{
                  fontSize: "14px",
                  color: "#a8b3cf",
                }}
              >
                <span>
                  {formatDate(post.created_at)} • {post.time_to_read}m read
                </span>
              </div>
            </h1>
            {post.tldr && (
              <div
                className="row-alignment"
                style={{
                  display: "flex",
                  alignItems: "stretch", // This ensures children stretch to fill the container height
                  gap: "8px",
                  marginTop: "12px",
                }}
              >
                {/* Vertical Bar */}
                <div
                  style={{
                    width: "4px",
                    backgroundColor: "#ce3df3", // Your accent color
                    borderRadius: "2px",
                    flexShrink: 0, // Prevents the bar from shrinking
                  }}
                />
                {/* TLDR Content */}
                <div style={{ fontSize: "18px", color: "#fff" }}>
                  <strong style={{ color: "#ce3df3" }}>TLDR</strong> {post.tldr}
                </div>
              </div>
            )}
            {post.tags && (
              <div
                className="row-alignment"
                style={{ margin: "12px 0", gap: "8px", flexWrap: "wrap" }}
              >
                {post.tags.split(",").map((tag, index) => (
                  <div
                    key={index}
                    className="tag-tile"
                    style={{
                      padding: "2px 8px",
                      borderRadius: "8px",
                      fontSize: "12px",
                      color: "#a8b3cf",
                      fontWeight: "500",
                      background: "#1a1f26",
                    }}
                  >
                    #{tag.trim()}
                  </div>
                ))}
              </div>
            )}
            {(post.image || post.resume_url) && (
              <img
                src={post.image || post.resume_url}
                alt={post.title}
                onClick={() => window.open(`${post.link}`, "_blank")}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "16px",
                  marginBottom: "24px",
                  objectFit: "cover",
                  margin: "auto",
                }}
              />
            )}

            {post.category === "resume" && (
              <>
                <div
                  className="column-alignment"
                  style={{ margin: "16px 0 54px 0" }}
                >
                  {loadingComments ? (
                    <div
                      className="column-alignment align-center"
                      style={{ margin: "12px 0" }}
                    >
                      <Preloader size="42" />
                    </div>
                  ) : (
                    <>
                      <span className="no-of-comments-text">
                        {noOfComments} comments
                      </span>
                      <div className="column-alignment" style={{ gap: "16px" }}>
                        {comments.map((comment, index) => {
                          return (
                            <CommentWrapper comment={comment} key={index} />
                          );
                        })}
                      </div>
                    </>
                  )}

                </div>

                <Button style={{ all: "unset" }} popupOpen=".add-comment-sheet">
                  <div className="column-alignment share-thoughts-wrapper">
                    <div className="column-alignment share-thoughts-container">
                      <span>Share your thoughts</span>
                    </div>
                  </div>
                </Button>
              </>
            )}
          </div>
        </Block>
        <AddCommentSheet
          fetchCommentsData={fetchCommentsData}
          resumeId={post.id}
        />

      </Page>
    </>
  );
};

export default ReadPostScreen;
