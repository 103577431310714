import React from "react";
import "./card.css"; // Ensure to update your CSS for dark theme
import { f7 } from "framework7-react";
import { FaCalendarAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const TopmateCard = ({ title, subtitle, iframeSrc }) => {
  const handleCardClick = () => {
    f7.views.main.router.navigate("/iframe", {
      props: {
        iframeSrc: iframeSrc,
      },
      transition: "f7-circle",
    });
  };

  return (
    <div
      className="column-alignment topmate-card-container dark-theme"
      onClick={handleCardClick}
    >
      <div className="card-header">
        <div className="rating">
          <FaStar /> 5
        </div>
      </div>
      <div className="card-content column-alignment">
        <div>
          <span className="topmate-card-text">{title}</span>

          {subtitle && (
            <p className="topmate-card-subtitle">
              <FaCalendarAlt /> &nbsp;
              {subtitle}
            </p>
          )}
        </div>

        <div className="pricing row-alignment">
          <span className="align-self-center" style={{ fontWeight: "600" }}>
            Digital Product
          </span>
          <span className="fill"></span>
          <div
            className="row-alignment"
            style={{
              background: "black",
              padding: "8px",
              borderRadius: "28px",
            }}
          >
            <span className="original-price align-self-center">499</span>
            <span className="current-price align-self-center">299</span>
            <span className="arrow align-self-center">→</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopmateCard;
