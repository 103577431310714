import { Block, PageContent, Sheet, f7 } from "framework7-react";

import {
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappIcon,
  TelegramIcon,
  XIcon,
} from "react-share";

import { WhatsappLogo, TelegramLogo } from "@phosphor-icons/react";

const ProfileShareBottomSheet = ({ profileLink }) => {
  const shareMessage = "View Profile";
  const handleClose = () => {
    f7.sheet.close(".profile-share-bottom-sheet");
  };

  const handleCopyProfileLink = () => {
    if (profileLink) {
      // Copy the referral link to the clipboard
      navigator.clipboard
        .writeText(profileLink)
        .then(() => {
          // Show a Telegram popup to confirm that the link was copied
          window.Telegram.WebApp.showPopup({
            title: "Copied!",
            message: "Profile link has been copied.",
            buttons: [{ text: "OK", type: "ok" }],
          });
        })
        .catch((err) => {
          // Handle any errors that occur during the copy process
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <Sheet
      className="profile-share-bottom-sheet"
      style={{ height: "auto" }}
      push
      backdrop
    >
      <PageContent
        className=""
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          background: "#282829",
        }}
      >
        <Block
          className="column-alignment"
          style={{ padding: "16px", marginTop: 0, width: "100%" }}
        >
          <div
            className="row-alignment"
            style={{ paddingBottom: "16px", borderBottom: "1px solid #5a5a5a" }}
          >
            <span
              className="theme-opposite-color-medium-text-global"
              style={{ margin: "0 auto" }}
            >
              Share Profile
            </span>
            {/* <span className="fill"></span> */}
            <i
              className="f7-icons"
              onClick={handleClose}
              style={{ pointer: "cursor" }}
            >
              multiply
            </i>
          </div>

          <div
            className="row-alignment align-center profile-share-bottom-sheet-sub-wrapper"
            style={{ gap: "4px" }}
            onClick={handleCopyProfileLink}
          >
            <span>Copy Link</span>
            <i className="f7-icons" style={{ fontSize: "14px" }}>
              link
            </i>
          </div>

          <div className="row-alignment social-handles-container profile-share-bottom-sheet-sub-wrapper">
            <div className="social-handle-wrapper column-alignment">
              <TwitterShareButton
                url={profileLink}
                title={shareMessage}
                className="social-handle-share-button"
              >
                <i
                  class="fa-brands fa-x-twitter"
                  style={{ fontSize: "32px" }}
                ></i>
              </TwitterShareButton>
            </div>
            <div className="social-handle-wrapper column-alignment">
              <LinkedinShareButton
                url={profileLink}
                title={shareMessage}
                className="social-handle-share-button"
              >
                <i
                  class="fa-brands fa-linkedin"
                  style={{ fontSize: "32px" }}
                ></i>
              </LinkedinShareButton>
            </div>
            <div className="social-handle-wrapper column-alignment">
              <TelegramShareButton
                url={profileLink}
                title={shareMessage}
                className="social-handle-share-button"
              >
                <i
                  class="fa-brands fa-telegram"
                  style={{ fontSize: "32px" }}
                ></i>
              </TelegramShareButton>
            </div>
            <div className="social-handle-wrapper">
              <WhatsappShareButton
                url={profileLink}
                title={shareMessage}
                separator=":: "
                className="social-handle-share-button"
              >
                <i
                  class="fa-brands fa-whatsapp"
                  style={{ fontSize: "32px" }}
                ></i>
              </WhatsappShareButton>
            </div>
          </div>
        </Block>
      </PageContent>
    </Sheet>
  );
};

export default ProfileShareBottomSheet;
