import React, { useState } from "react";
import "./quick-take-card.css";

const QuickTakeCard = ({ quickTake }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Helper function to count the number of words in the answer
  const wordCount = (text) => text.split(" ").length;

  // Function to handle "see more" and "see less" toggling
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Set the limit for word count
  const wordLimit = 50;

  // Check if the answer exceeds the word limit
  const isLongText = wordCount(quickTake.answer) > wordLimit;

  // Function to truncate text to the specified word limit
  const getTruncatedText = (text) =>
    text.split(" ").slice(0, wordLimit).join(" ") + "...";

  return (
    <div className="column-alignment quick-take-card" style={{ gap: "8px" }}>
      <h2>{quickTake.question}</h2>
      <img
        src={quickTake.bannerUrl}
        alt={quickTake.question}
        className="quick-take-card-image"
      />
      <span>
        {/* Show truncated text if the answer is long and not expanded */}
        {isExpanded || !isLongText
          ? quickTake.answer
          : getTruncatedText(quickTake.answer)}
      </span>
      {isLongText && (
        <span onClick={toggleExpanded} className="show-more-button">
          {isExpanded ? "Show less" : "Show more"}
        </span>
      )}
    </div>
  );
};

export default QuickTakeCard;
