import {
  Block,
  PageContent,
  Sheet,
  f7,
  List,
  ListInput,
  Preloader,
} from "framework7-react";
import "./read-post-screen.css";
import { useState } from "react";

const AddCommentSheet = ({ fetchCommentsData, resumeId }) => {
  const [comment, setComment] = useState(""); // State to store user input
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    f7.sheet.close(".add-comment-sheet");
    fetchCommentsData();
  };

  const handleCommentSubmit = async () => {
    if (comment.trim() === "") {
      f7.dialog.alert("Please enter a comment");
      return;
    }

    try {
      setIsLoading(true);
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/add-comment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            resume_id: resumeId,
            comment: comment,
          }),
        }
      );

      if (response.ok) {
        f7.dialog.alert("Comment added successfully");
        setComment(""); // Clear the textarea
        handleClose(); // Close the sheet after successful submission
      } else {
        f7.dialog.alert("Failed to add comment");
      }
    } catch (error) {
      f7.dialog.alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sheet
      className="add-comment-sheet"
      style={{ height: "100%" }}
      push
      backdrop
    >
      <PageContent
        className=""
        style={{
          borderRadius: 0,
          background: "#0e1217",
        }}
      >
        {/* Header */}
        <div
          className="row-alignment"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            padding: "16px",
            zIndex: "99999999",
            borderBottom:
              "1px solid color-mix(in srgb, #a8b3cf, transparent 80%)",
          }}
        >
          <span
            className="align-self-center"
            onClick={handleClose}
            style={{ fontSize: "16px", fontWeight: 700, color: "#a8b3cf" }}
          >
            Cancel
          </span>
          <span className="fill"></span>
          <span
            onClick={!isLoading ? handleCommentSubmit : null} // Disable button when loading
            style={{
              fontSize: "16px",
              fontWeight: "700",
              borderRadius: "12px",
              padding: "14px 20px",
              lineHeight: "18px",
              background: "#fff",
              color: "#000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px", // Ensure button size remains consistent
            }}
          >
            {isLoading ? <Preloader size={16} color="black" /> : "Comment"}
          </span>
        </div>

        {/* Comment Textarea */}
        <Block
          className="column-alignment"
          style={{
            padding: "80px 8px 8px 8px",
            margin: 0,
            width: "100%",
            height: "calc(100vh - 80px)",
          }}
        >
          <div className="column-alignment share-thoughts-textarea">
            <List noHairlinesMd>
              <ListInput
                type="textarea"
                value={comment} // Bind the state
                onInput={(e) => setComment(e.target.value)} // Update state on input
                placeholder="Share your thoughts"
                resizable
                clearButton
                style={{
                  color: "#fff",
                }}
              />
            </List>
          </div>
        </Block>
      </PageContent>
    </Sheet>
  );
};

export default AddCommentSheet;
