import { Block, f7, Navbar, NavLeft, NavRight } from "framework7-react";
import logo from "../images/logo.svg";
import WebApp from "@twa-dev/sdk";
function NavBar({
  title,
  showVape,
  showClock,
  showBackButton,
  showReadPost,
  postRedirectLink,
  showUserName,
  username,
  showJoinCommunity,
  showLogo
}) {
  const handleMoveBack = () => {
    f7.views.main.router.back({ reloadAll: true });
  };

  return (
    <Navbar style={{ position: "fixed" }}>
      <NavLeft>
        {showBackButton && (
          <i
            className="f7-icons align-self-center"
            style={{
              marginRight: "8px",
              color: "var(--theme-opposite-color)",
              cursor: "pointer",
              fontSize: "22px",
            }}
            onClick={handleMoveBack}
          >
            chevron_left
          </i>
        )}
        {showLogo && (
          <img
            src={logo}
            style={{ height: "18px", objectFit: "contain" }}
            className="align-self-center"
          />
        )}
      </NavLeft>
      <NavRight>
        {showUserName && (
          <div className="row-alignment">
            <span
              className="theme-opposite-color-medium-text-global"
              style={{
                padding: "4px 8px",
                fontSize: "14px",
                color: "#a8b3cf",
                fontWeight: "500",
              }}
            >
              {username}
            </span>
          </div>
        )}
        {showJoinCommunity && (
          <div className="row-alignment">
            <span
              onClick={() => WebApp.openTelegramLink("https://t.me/tigestclub")}
              className="theme-opposite-color-medium-text-global"
              style={{
                padding: "4px 8px",
                borderRadius: "8px",
                fontSize: "14px",
                color: "#a8b3cf",
                fontWeight: "500",
                background: "#1a1f26",
              }}
            >
              Join Community
            </span>
          </div>
        )}

        {showReadPost && (
          <span
            onClick={() => window.open(`${postRedirectLink}`, "_blank")}
            style={{
              padding: "4px 8px",
              borderRadius: "8px",
              fontSize: "14px",
              color: "#a8b3cf",
              fontWeight: "500",
              background: "#1a1f26",
            }}
          >
            Read post
          </span>
        )}
      </NavRight>

      {/* <span className="fill"></span> */}
    </Navbar>
  );
}

export default NavBar;
