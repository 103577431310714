import { Page, Block } from "framework7-react";
import "./upcoming-event-page.css";
import { Navbar, NavRight } from "framework7-react";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import sampleImage from "../../assets/images/sample-attendee.png";

const UpcomingEventPage = ({ event }) => {
  return (
    <Page>
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      <Block>
        <div className="event-header">
          {/* <div className="event-tag">Event</div> */}
          <img
            src={event.bannerUrl}
            alt="Event"
            className="event-image"
            style={{
              borderTopLeftRadius: "26px",
              borderTopRightRadius: "26px",
            }}
          />

          <div className="column-alignment" style={{ padding: "16px" }}>
            <h1 className="event-title">{event.title}</h1>

            <div className="event-info">
              <div className="date-time">
                <div className="date">
                  <span className="month">{event.month}</span>
                  <span className="day">{event.date}</span>
                </div>
                <div className="time">
                  {event.date} {event.month}
                  <br />
                  <span className="subtext">{event.time}</span>
                </div>
              </div>
              <div className="location">
                <i className="f7-icons">videocam_fill</i>

                <div className="column-alignment">
                  {event.location}
                  <span className="subtext">Register to get link</span>
                </div>
              </div>
              <div className="price">
                <i className="f7-icons">ticket_fill</i>
                Free
              </div>
            </div>
          </div>
        </div>

        <div className="event-details event-container column-alignment">
          <div
            className="column-alignment"
            style={{ borderBottom: "1px solid #333333" }}
          >
            <h2>
              Hosted on <br />
              Creator Community
            </h2>
            <div className="host-info row-alignment">
              <span className="subtext">With Matt Keston</span>
              <img
                src="https://nas.io/_next/image?url=https%3A%2F%2Fd1ln96rzxchhx.cloudfront.net%2F1689517772219.jpg&w=128&q=75"
                alt="Host"
                className="host-image"
              />
            </div>
          </div>

          <div
            className="event-location"
            style={{ borderBottom: "1px solid #333333" }}
          >
            <h3>Where</h3>
            <div className="location-item">
              <i className="f7-icons">globe</i>
              Online
            </div>
          </div>

          <div className="attendees">
            <h3>1 people attending</h3>
            <img src={sampleImage} alt="Attendee" className="attendee-image" />
          </div>

          <button className="spread-word-button align-self-start">
            Spread the word
          </button>
        </div>
      </Block>
    </Page>
  );
};

export default UpcomingEventPage;
