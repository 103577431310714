import { useEffect, useState } from "react";
import {
  Block,
  Page,
  f7,
  Navbar,
  NavRight,
  Tabs,
  Tab,
  Link,
  Toolbar,
  Button,
} from "framework7-react";
import "./home-screen.css";
import PostCard from "../../components/post-card/post-card";
import AuthorProfile from "../../components/author-card/author-card";
import WebApp from "@twa-dev/sdk";
import { v4 as uuidv4 } from "uuid";
import { postHogHandler } from "../../Posthog/posthog";
import SkeletonPosts from "../../components/skeleton/skeleton-posts/skeleton-posts";
import SkeletonJobs from "../../components/skeleton/skeleton-jobs/skeleton-jobs";
import SkeletonTopVoice from "../../components/skeleton/skeleton-top-voice/skeleton-top-voice";
import "react-loading-skeleton/dist/skeleton.css";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import resumeReviewImage from "../../assets/images/resume-review-image.jpg";
import ResumeReviewPage from "../resume-review-page/resume-review-page";
import LockedScreen from "./locked-screen";

const HomeScreen = () => {
  const [posts, setPosts] = useState([]);
  const [approvedResumePosts, setApprovedResumePosts] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingAuthors, setLoadingAuthors] = useState(true);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [resumeLoading, setResumeLoading] = useState(true);
  const [isEligible, setIsEligible] = useState(false); // To track eligibility for resume review
  const [loadingEligibility, setLoadingEligibility] = useState(true); // To track API loading state of resume review
  const [services, setServices] = useState([]);
  // Initial filter state with all selected
  const [selectedFilters, setSelectedFilters] = useState({
    creator: true,
    founder: true,
    hr: true,
  });

  // Toggle filter state
  const toggleFilter = (category) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Filter authors based on selected categories
  const filteredAuthors = authors.filter((author) => {
    if (selectedFilters.creator && author.category === "creator") return true;
    if (selectedFilters.founder && author.category === "founder") return true;
    if (selectedFilters.hr && author.category === "hr") return true;
    return false;
  });

  const resumeReviewCard = {
    title: "Free Resume Review: Click Here to Get a Free Review",
    category: "resume-review",
    image: resumeReviewImage, // Optional image
    link: "/resume-review",
    tags: "career, resume, review",
  };

  const checkResumeReviewEligibility = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/check-bundle-status?bundle_uuid=27982372-5706-499e-b7b5-e95e675fc930",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await response.json();
      if (responseJson.success) {
        setIsEligible(responseJson.data.purchased);
        setServices(responseJson.data.services);
      }
    } catch (error) {
      console.error("Error checking user status:", error);
    } finally {
      setLoadingEligibility(false);
    }
  };

  useEffect(() => {
    checkResumeReviewEligibility(); // Call the eligibility check when the component mounts
  }, []);

  useEffect(() => {
    const login = async () => {
      try {
        const data = WebApp.initData;
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
          }
        );

        if (response.ok) {
          const loginData = await response.json();
          const { token, profile } = loginData.data;
          localStorage.setItem("authToken", token);
          localStorage.setItem("profile", JSON.stringify(profile));
          const sessionId = uuidv4();
          localStorage.setItem("sessionId", sessionId);
          redirectIfAuthorPath();
        } else {
          console.error("Failed to login");
        }

        fetchApprovedResumeData(setApprovedResumePosts, setResumeLoading);
        fetchAuthors();
      } catch (error) {
        console.error("Login error:", error);
      }
    };

    const fetchApprovedResumeData = async (setData, setLoading) => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-approved-resumes",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setData(data.data);
        } else {
          console.error(`Failed to fetch approved resumes`);
        }
      } catch (error) {
        console.error(`Error fetching approved resumes`, error);
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async (category, setData, setLoading) => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://tigest.abhishek-b15.workers.dev/articles/${category}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setData(data.data);
        } else {
          console.error(`Failed to fetch ${category}`);
        }
      } catch (error) {
        console.error(`Error fetching ${category}:`, error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAuthors = async (token) => {
      try {
        setLoadingAuthors(true);
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/get-authors",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAuthors(data.data);
        } else {
          console.error("Failed to fetch authors");
        }
      } catch (error) {
        console.error("Error fetching authors:", error);
      } finally {
        setLoadingAuthors(false);
      }
    };

    const redirectIfAuthorPath = () => {
      const currentPath = window.location.pathname;
      const authorIdParam = WebApp.initDataUnsafe.start_param;
      if (currentPath.includes("/author/") || authorIdParam) {
        const pathParts = currentPath.split("/");
        const authorId = authorIdParam ?? pathParts[pathParts.length - 1];
        if (authorId) {
          postHogHandler("home-page-author", {
            uuid: authorId,
          });

          f7.views.main.router.navigate("/author-screen", {
            props: { uuid: authorId },
          });
        }
      }
    };
    fetchData("post", setPosts, setLoadingPosts);
    fetchData("job", setJobs, setLoadingJobs);
    login();
  }, []);

  return (
    <Page pageContent={false}>
      <Navbar>
        <NavRight>
          <JoinCommunityButton />
          <i
            className="f7-icons"
            style={{ margin: "8px", fontSize: "24px" }}
            onClick={() => f7.views.main.router.navigate("/user-profile")}
          >
            person_alt_circle
          </i>
        </NavRight>
      </Navbar>

      {/* <Button
        style={{ marginTop: "88px" }}
        onClick={() => f7.views.main.router.navigate("/comments-section")}
      >
        Comments
      </Button> */}

      <Toolbar top scrollable tabbar>
        <Link
          style={{ fontSize: 14, maxWidth: 500 }}
          tabLink="#posts"
          tabLinkActive
        >
          Feed
        </Link>
        <Link style={{ fontSize: 14, maxWidth: 500 }} tabLink="#jobs">
          Jobs
        </Link>
        <Link style={{ fontSize: 14, maxWidth: 500 }} tabLink="#top-voice">
          Leaders
        </Link>
        <Link style={{ fontSize: 14, maxWidth: 500 }} tabLink="#resume-review">
          Resume Review
        </Link>
      </Toolbar>

      <Tabs swipeable>
        <Tab id="posts" className="page-content" tabActive>
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingPosts ? (
                  <SkeletonPosts />
                ) : (
                  posts.map((post, index) => {
                    return (
                      <PostCard key={index} post={post} showBookMark={false} />
                    );
                  })
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="jobs" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingJobs ? (
                  <SkeletonJobs />
                ) : (
                  jobs.map((post, index) => (
                    <PostCard key={index} post={post} showBookMark={false} />
                  ))
                )}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="top-voice" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0, marginTop: "18px" }}>
            <div className="column-alignment">
              {/* Add Filter Buttons */}
              <Block
                style={{
                  padding: "10px",
                  textAlign: "center",
                  margin: 0,
                  gap: "6px",
                }}
                className="row-alignment"
              >
                <Button
                  className={
                    selectedFilters.creator ? "button-fill-custom" : ""
                  }
                  onClick={() => toggleFilter("creator")}
                >
                  Creator
                </Button>
                <Button
                  className={
                    selectedFilters.founder ? "button-fill-custom" : ""
                  }
                  onClick={() => toggleFilter("founder")}
                >
                  Founder
                </Button>
                <Button
                  className={selectedFilters.hr ? "button-fill-custom" : ""}
                  onClick={() => toggleFilter("hr")}
                >
                  HR
                </Button>
              </Block>
              <div className="column-alignment grid grid-cols-1">
                {loadingAuthors
                  ? SkeletonTopVoice
                  : filteredAuthors.map((author, index) => (
                      <AuthorProfile
                        key={index}
                        author={author}
                        showBookMark={false}
                      />
                    ))}
              </div>
            </div>
          </Block>
        </Tab>
        <Tab id="resume-review" className="page-content">
          <Block style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="column-alignment">
              <div className="column-alignment grid grid-cols-1">
                {loadingEligibility ? (
                  <SkeletonPosts />
                ) : isEligible ? (
                  resumeLoading ? (
                    <SkeletonPosts />
                  ) : (
                    [resumeReviewCard, ...approvedResumePosts].map(
                      (post, index) => (
                        <PostCard
                          key={index}
                          post={post}
                          showBookMark={false}
                        />
                      )
                    )
                  )
                ) : (
                  <LockedScreen
                    services={services.map((serviceObj) => ({
                      name: serviceObj.services.name,
                      cost: serviceObj.services.required_stars,
                    }))}
                    onStarsPayment={() => {
                      console.log("stars Payment");
                    }}
                  />
                )}
              </div>
            </div>
          </Block>
        </Tab>
      </Tabs>
    </Page>
  );
};

export default HomeScreen;
