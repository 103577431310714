import "./career-journey-card.css";
import { FaDatabase } from "react-icons/fa";
import { Block, List, ListItem } from "framework7-react";

const CareerJourneyCard = ({ experiences }) => {
  console.log("dfghj", experiences);
  return (
    <div className="column-alignment career-journey-container">
      {experiences?.map((exp, index) => {
        return (
          <div className="row-alignment experience-wrapper">
            <div className="column-alignment timeline-container">
              <span>{exp.startDate}</span>
              <span>{exp.endDate}</span>
              <span>{exp.duration}</span>
            </div>
            {/* Vertical divider with a dot */}
            <div className="timeline-divider">
              <span className="timeline-dot"></span>
            </div>
            <div className="column-alignment experience-container">
              <span>{exp.title}</span>
              <div className="row-alignment">
                {/* <img src={exp.logo} alt={exp.title} /> */}
                <span>{exp.company}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CareerJourneyCard;
