import { useState, useEffect } from "react";
import {
  Page,
  Block,
  Input,
  Button,
  f7,
  Navbar,
  NavRight,
  NavLeft,
} from "framework7-react";
import JoinCommunityButton from "../../components/buttons/join-community-button/join-community-button";
import "./resume-review-page.css";
import WebApp from "@twa-dev/sdk";

const ResumeReviewPage = () => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [resumeImage, setResumeImage] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    const checkUserStatus = async () => {
      //   const data = WebApp.initData;
      //   const decodedString = decodeURIComponent(data);
      //   const params = new URLSearchParams(decodedString);
      //   const userJson = params.get("user");
      //   const user = JSON.parse(userJson);
      //   const userId = user.id;
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(
          "https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/check-user",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          setIsMember(data.data.isMember);
        } else {
          f7.dialog.alert("Failed to check user status. Please try again.");
        }
      } catch (error) {
        console.error("Error checking user status:", error);
        f7.dialog.alert("Error checking user status. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, []);

  const handleFileUpload = (event) => {
    setResumeImage(event.target.files[0]);
    setSelectedFile(event.target.files[0].name);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("authToken");
    if (!title || !details || !resumeImage) {
      f7.dialog.alert("Please fill in all fields and upload a resume.");
      return;
    }

    // Create form data to send to backend
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", details);
    formData.append("resume", resumeImage);

    // Example API request to send data
    fetch("https://iwdbctqutzrugdafslbh.supabase.co/functions/v1/add-resume", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          f7.dialog.alert("Resume submitted successfully!");
        } else {
          f7.dialog.alert("Failed to submit resume. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error submitting resume:", error);
        f7.dialog.alert("Error submitting resume. Please try again.");
      });
  };

  if (isLoading) {
    return (
      <Page>
        <Block>Loading...</Block>
      </Page>
    );
  }

  return (
    <Page style={{ backgroundColor: "#000" }}>
      <Navbar backLink="Back" backLinkShowText={false}>
        <NavLeft>
          <span style={{ fontSize: "14px" }}>Submit resume</span>
        </NavLeft>
        <NavRight>
          <JoinCommunityButton />
        </NavRight>
      </Navbar>
      <div className="column-alignment" style={{ height: "100%" }}>
        <Block
          className="column-alignment align-center"
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              backgroundColor: "#1e1e1e", // Dark background
              borderRadius: "8px",
              padding: "24px",
              maxWidth: "500px",
              width: "100%",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              position: "relative",
              zIndex: 1,
              flex: "1",
            }}
          >
            {!isMember ? (
              <Block
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  zIndex: 2,
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  Please join the community to access this form.
                </p>
              </Block>
            ) : null}

            <form
              className="form-container"
              style={{
                pointerEvents: isMember ? "auto" : "none",
                flex: "1 1 auto",
              }}
            >
              <div className="form-group" style={{ marginBottom: "16px" }}>
                <label htmlFor="title" style={{ color: "#fff" }}>
                  {" "}
                  {/* White text color */}
                  Title
                </label>
                <Input
                  type="text"
                  placeholder="Enter the title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  style={{
                    borderColor: "#555", // Dark border color
                    marginTop: "4px",
                    padding: "16px",
                    color: "#fff", // White text color
                    backgroundColor: "#2c2c2c",
                    borderRadius: "6px",
                    border: "2px dashed #555", // Dark input background
                  }}
                />
              </div>
              <div className="form-group" style={{ marginBottom: "16px" }}>
                <label htmlFor="details" style={{ color: "#fff" }}>
                  Details
                </label>
                <Input
                  resizable
                  type="textarea"
                  placeholder="Enter additional details or description"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  required
                  style={{
                    borderColor: "#555",
                    marginTop: "4px",
                    padding: "16px",
                    color: "#fff",
                    backgroundColor: "#2c2c2c",
                    borderRadius: "6px",
                    border: "2px dashed #555",
                  }}
                />
              </div>
              <div className="form-group" style={{ marginBottom: "24px" }}>
                <label htmlFor="resume" style={{ color: "#fff" }}>
                  Upload Resume
                </label>
                <div style={{ position: "relative", marginTop: "4px" }}>
                  {/* Hidden File Input */}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    required
                    id="file-upload"
                    style={{
                      display: "none", // Hides the default file input
                    }}
                  />

                  {/* Custom Label to Trigger File Upload */}
                  <label
                    htmlFor="file-upload" // This should match the input id
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      border: "2px dashed #555",
                      padding: "16px",
                      backgroundColor: "#333",
                      color: "#fff",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className="f7-icons align-self-center"
                      style={{ marginRight: "8px", fontSize: "16px" }}
                    >
                      cloud_upload_fill
                    </i>
                    {selectedFile ? selectedFile : "Upload File"}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </Block>

        <span className="fill"></span>

        <Button
          fill
          onClick={handleSubmit}
          style={{
            backgroundColor: "#1a1f26",
            color: "#a8b3cf",
            borderRadius: "8px",
            margin: "16px",
            padding: "20px",
          }}
        >
          Submit
        </Button>
      </div>
    </Page>
  );
};

export default ResumeReviewPage;
